<template>
  <div style="width: 100%;">
    <van-steps direction="vertical" :active="defaultIndex">
      <!-- 班主任 -->
      <van-step class="audit-detail" v-if="showView1 && level1Data" style="margin-top:8px">
        <div class="audit-detail-title">
          <van-row>
            <van-col span="14" :style="getSatusLabel(level1Data.status) !== '审核通过' ?'color: rgb(255, 77, 79);':'color: rgb(35, 171, 114)' ">班主任{{getSatusLabel(level1Data.status)}}</van-col>
            <van-col span="10" style=" font-weight: bold;text-align: right;">审批人:{{level1Data.approverName}}</van-col>
          </van-row>
        </div>
        <div class="audit-detail-div">
          <div>
            <van-icon name="tosend" size="14" /> 审核时间:{{level1Data.createTime}}
          </div>
          <div style="margin-top:4px;display: -webkit-inline-box;">
            <div style="width:80px;">
              <van-icon name="comment-o" size="14" /> 审批意见:
            </div>
            <div style="width:75%;">{{level1Data.opinion}}</div>
          </div>
        </div>
      </van-step>

      <!-- 学院副书记 -->
      <van-step class="audit-detail" v-if="showView2 && level2Data">
        <div class="audit-detail-title">
          <van-row>
            <van-col span="14" :style="getSatusLabel(level2Data.status) !== '审核通过' ?'color: rgb(255, 77, 79);':'color: rgb(35, 171, 114)'">学院副书记{{getSatusLabel(level2Data.status)}}</van-col>
            <van-col span="10" style=" font-weight: bold;text-align: right;">审批人:{{level2Data.approverName}}</van-col>
          </van-row>
        </div>
        <div class="audit-detail-div">
          <div>
            <van-icon name="tosend" size="14" /> 审核时间:{{level2Data.createTime}}
          </div>
          <div style="margin-top:4px;display: -webkit-inline-box;">
            <div style="width:80px;">
              <van-icon name="comment-o" size="14" /> 审批意见:
            </div>
            <div style="width:75%;">{{level2Data.opinion}}</div>
          </div>
        </div>
      </van-step>
      <!-- 用工部门负责人 -->
      <van-step class="audit-detail" v-if="showView4  && level4Data">
        <div class="audit-detail-title">
          <van-row>
            <van-col span="14" :style="getSatusLabel(level4Data.status) !== '审核通过' ?'color: rgb(255, 77, 79);':'color: rgb(35, 171, 114)'">用工部门负责人{{getSatusLabel(level4Data.status)}}</van-col>
            <van-col span="10" style=" font-weight: bold;text-align: right;">审批人:{{level4Data.approverName}}</van-col>
          </van-row>
        </div>
        <div class="audit-detail-div">
          <div>
            <van-icon name="tosend" size="14" /> 审核时间:{{level4Data.createTime}}
          </div>
          <div style="margin-top:4px;display: -webkit-inline-box;">
            <div style="width:80px;">
              <van-icon name="comment-o" size="14" /> 审批意见:
            </div>
            <div style="width:75%;">{{level4Data.opinion}}</div>
          </div>
        </div>
      </van-step>

      <!-- 学生处 -->
      <van-step class="audit-detail" v-if="showView3  && level3Data">
        <div class="audit-detail-title">
          <van-row>
            <van-col span="14" :style="getSatusLabel(level3Data.status) !== '审核通过' ?'color: rgb(255, 77, 79);':'color: rgb(35, 171, 114)'">学生处{{getSatusLabel(level3Data.status)}}</van-col>
            <van-col span="10" style=" font-weight: bold;text-align: right;">审批人:{{level3Data.approverName}}</van-col>
          </van-row>
        </div>
        <div class="audit-detail-div">
          <div>
            <van-icon name="tosend" size="14" /> 审核时间:{{level3Data.createTime}}
          </div>
          <div style="margin-top:4px;display: -webkit-inline-box;">
            <div style="width:80px;">
              <van-icon name="comment-o" size="14" /> 审批意见:
            </div>
            <div style="width:75%;">{{level3Data.opinion}}</div>
          </div>
        </div>
      </van-step>
    </van-steps>
    <div class="audit-detail-null" v-if="!showView1 && !showView2 && !showView3  && !showView4 && !level1Data && !level2Data && !level3Data && !level4Data"> 暂无审核信息</div>
  </div>
</template>

<script>
import { listGetOne } from "@/api/common/approval";
export default {
  props: {
    appParams: {
      type: Object,
    },
  },
  data() {
    return {
      defaultIndex: 0,
      level0: [1, 101, 108, 9],
      level1: [2, 101, 108],
      level4: [2, 201, 208],
      level2: [6, 601, 608],
      level3: [9, 301, 308],
      level1Data: {},
      level2Data: {},
      level3Data: {},
      level4Data: {},
      showView1: false,
      showView2: false,
      showView3: false,
      showView4: false,
    };
  },
  created() {
    if (
      this.appParams.coreKey &&
      this.appParams.coreCode &&
      this.appParams.mkCode &&
      this.appParams.status
    ) {
      this.init(
        this.appParams.coreKey,
        this.appParams.mkCode,
        this.appParams.coreCode,
        this.appParams.status,
        this.appParams.level
      );
    }
  },
  methods: {
    // 业务ID 审批项目类型 审批业务类型 目前的审批状态spzt int
    init(coreKey, mkCode, coreCode, spzt, level) {
      let status = parseInt(spzt)
      if (level === 4) {
        if (this.level1.indexOf(status) > -1) {
          // 班主任
          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "1",
          }).then((res) => {
            this.level1Data = res;
          });
          this.defaultIndex = 0;
          this.showView1 = true;
          this.showView2 = false;
          this.showView3 = false;
        } else if (this.level2.indexOf(status) > -1) {
          //学院
          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "1",
          }).then((res) => {
            this.level1Data = res;
          });
          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "2",
          }).then((res) => {
            this.level2Data = res;
          });
          this.defaultIndex = 1;
          this.showView1 = true;
          this.showView2 = true;
          this.showView3 = false;
        } else if (this.level4.indexOf(status) > -1) {
          // 学生处
          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "1",
          }).then((res) => {
            this.level1Data = res;
            this.showView1 = true;
          });

          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "2",
          }).then((res) => {
            this.level2Data = res;
            this.showView2 = true;
          });

          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "6",
          }).then((res) => {
            if (res) {
              this.level4Data = res;
              this.showView4 = true;
              this.defaultIndex = 2;
            } else {
              this.showView4 = false;
              this.defaultIndex = 1;
            }
          });
        } else if (this.level3.indexOf(status) > -1) {
          // 学生处
          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "1",
          }).then((res) => {
            this.level1Data = res;
            this.showView1 = true;
          });

          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "2",
          }).then((res) => {
            this.level2Data = res;
            this.showView2 = true;
          });

          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "6",
          }).then((res) => {
            this.level4Data = res;
            this.showView4 = true;
          });

          listGetOne({
            coreKey: coreKey,
            mkCode: mkCode,
            coreCode: coreCode,
            field3: "3",
          }).then((res) => {
            if (res) {
              this.level3Data = res;
              this.showView3 = true;
              this.defaultIndex = 3;
            } else {
              this.showView3 = false;
              this.defaultIndex = 2;
            }
          });
        }
      }
    },
    getStatusType(status) {
      if (status === 8) {
        return "info";
      }
      if (status === 9) return "error";
      if (status === 1) return "success";
      return "info";
    },
    getSatusLabel(key) {
      if (!key) {
        return "待审核";
      }
      if (key === 8) {
        return "审核驳回";
      } else if (key === 9) {
        return "审核失败";
      } else {
        return "审核通过";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audit-detail-null {
  padding: 17px;
  color: rgba(136, 136, 136, 1);
  font-size: 15px;
}
.audit-detail {
  &-div {
    margin: 12px 0 4px 0;
    border-radius: 6px;
    padding: 12px;
    line-height: 27px;
    font-size: 14px;
    color: rgb(136, 136, 136);
    background-color: rgba(35, 171, 114, 0.08);
  }
  &-title {
    width: 100%;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
    font-size: 15px;
  }
}

::v-deep .van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0px;
}
::v-deep .van-step__title {
  padding-right: 20px;
}
</style>
